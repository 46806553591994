import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatedDate', (value) => {
  if (value && value !== '') {
    return moment(value).format('DD/MM/YYYY')
  }
  return ''
})

Vue.filter('formatCurrency', (value) => {
  if (value && value !== '') {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value)
  }
  return '0'
})