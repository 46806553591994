<template lang="pug">
  b-modal(:id="'modal-cotization'" :visible="showModal" size="xl" hide-footer @hide="handleModalClose" :no-close-on-backdrop="true")
    template(#modal-title)
      h5.modal-title Generar Cotización ID {{ item.id }}
    div
      div
        b-row
          b-col(cols="12")
            img(src="@/assets/icons/replacement.svg" alt="Repuestos" style="width: 23px; height: 23px; margin-right: 5px")
            span.title.ml-2 Repuestos

        b-row.mt-2( class="align-items-center")
          b-col(cols="6" v-for="(repuesto, index) in item.repuestos || []" :key="index")
            span.subtitle_repuesto Repuesto {{ index + 1 }}
            b-row.mt-2
              b-col(cols="12")
                b-form-group
                  label.categoria_text Categoría del Repuesto
                  span.categoria {{ repuesto.category.name }}
              b-col(cols="12")
                b-form-group
                  label.comentario_text Comentarios
                  span.comentarios {{ repuesto.comments }}
      b-row
        b-col(cols="12")
          img(src="@/assets/icons/money.svg" alt="Cotizar" style="width: 23px; height: 23px; margin-right: 5px")
          span.title.ml-2 Cotizar Repuestos
      b-row(v-for="(item, index) in items" :key="index" class="align-items-center").mt-4
        b-col(cols="4")
          b-form-group
            label.part-number Parte
            Autocomplete(
              :search="search"
              placeholder="Buscar Partes"
              aria-label="Buscar Partes"
              :default-value="item.partes_name"
              :get-result-value="getResultValue"
              @submit="(partSelected) => onSubmit(partSelected, index)"
            )
        b-col(cols="3")
          b-form-group
            label Cantidad
            b-input-group
              b-form-input(type="number" v-model="item.partes_cant" placeholder="Ingrese Cantidad")
        b-col(cols="3")
          b-form-group
            label Precio
            b-input-group
              b-form-input(type="number" v-model="item.precio_unitario" placeholder="Ingrese Precio")
        b-col(cols="1")
          b-form-group
            label Total
            <br>
            span {{ formatearPeso(item.partes_cant * item.precio_unitario || 0) }}
        b-col(cols="1").mt-3
          b-icon.trash-icon(icon="trash"  v-if="items.length > 1" @click="removeItem(index)")
      b-row
        b-col(cols="12")
          div.btn-add-item( @click="addItem").mt-2.mb-4
            font-awesome-icon.icon-add(icon="plus-square" size="sm")
            span.ml-2 Añadir Item
            b-row
        b-col(cols="6")
          b-form-group
            label Mano de Obra
            b-form-input(type="number" placeholder="Ingrese Monto en pesos" v-model="name")
      b-row
        b-col(cols="6" class="").d-flex.align-items-center.mb-2
            b-form-checkbox(v-model="checked" name="check-button").mr-2
            label.mt-2 Requiere Abono
      b-row
        b-col(cols="6")
          b-form-group
            label Abono Requerido
            b-form-input(type="number" placeholder="Ingrese Monto en pesos" :disabled="!checked" v-model="abono_requerido")
        b-col(cols="6")
          b-row
            b-col(cols="12").mt-2
              span.subtitle_repuesto SubTotal
              span.float-right {{ formatearPeso(subtotal.toFixed(2)) }}
            b-col(cols="12").mt-2
              span.subtitle_repuesto IVA
              span.float-right {{ formatearPeso(subtotal * 0.19) }}
            b-col(cols="12").mt-2
              span.subtitle_repuesto Total
              span.float-right {{ formatearPeso(subtotal * 1.19) }}
      b-row
        b-col(cols="12")
          b-form-group
            label Comentario
            b-form-textarea(placeholder="Ingrese algún dato de ser necesario" v-model="comentario"  class="textarea-height")
      b-button(variant="primary" @click="save") Guardar
  </template>

<script>
import { mapActions } from 'vuex';
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
  name: 'ModalCotization',
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    reloadList: {
      type: Function,
    },
  },
  components: {
    Autocomplete
  },
  data() {
    return {
      name: '',
      checked: false,
      abono_requerido: '',
      comentario: '',
      items: [{
        partes_name: '',
        partes_cant: '',
        precio_unitario: '',
        id_parte: ''
      }],
      inputText: null,
      searching: false,
      selectedPart: null,
    };
  },
  methods: {
    ...mapActions('Diagnosticos', ['searchByPartNumber']),
    ...mapActions('OVStore', ['sendQuotation']),
    formatearPeso(valor) {
      return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(valor);
    },
    closeModal() {
      this.$emit('close');
    },
    addItem() {
      this.items.push({
        partes_name: '',
        partes_cant: '',
        precio_unitario: '',
        id_parte: ''
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    async search(input) {
      if (input.length < 3) {
        return [];
      }
      try {
        const results = await this.searchByPartNumber({ partNumber: input });
        return results.data.results.map((result) => ({
          ...result,
        }));
      } catch (error) {
        console.error('Error al buscar partes:', error);
        return [];
      }
    },
    getResultValue(result) {
      return result.part_number;
    },
    onSubmit(partSelected, index) {
      this.items[index].id_parte = partSelected.id;
      this.items[index].partes_name = partSelected.part_number;
      this.selectedPart = partSelected;
    },
    async save() {

      const response = await this.sendQuotation({
        mano_de_obra: parseInt(this.name, 10),
        requiere_abono: this.checked,
        abono_requerido: this.abono_requerido ? this.abono_requerido : 0,
        comentario_vendedor: this.comentario,
        items: this.items,
        id_oportunidad_venta: this.item.id
      });
      console.log("response ", response);
      if (response.status === 200) {
        this.$bvToast.toast('Cotización creada.', {
          variant: 'success',
          solid: true
        })
        this.closeModal();
        this.reloadList();
      }
    },
    resetFields() {
      this.name = '';
      this.checked = false;
      this.abono_requerido = '';
      this.comentario = '';
      this.items = [
        {
          partes_name: '',
          partes_cant: '',
          precio_unitario: '',
          id_parte: ''
        }
      ];
      this.inputText = null;
      this.searching = false;
      this.selectedPart = null;
    },
    handleModalClose() {
      this.resetFields();
      this.closeModal();
    },
    validateAbono() {
    const abono = parseFloat(this.abono_requerido) || 0;
    if (abono > this.subtotal) {
      this.abono_requerido = ''; // Limpiar el campo de abono
      this.$bvToast.toast('El abono no puede ser mayor al 100% del total', {
        title: 'Advertencia',
        variant: 'danger',
        solid: true
      });
    }
  }
  },
  computed: {
    subtotal() {
      const itemsTotal = this.items.reduce((total, item) => {
        return total + (item.partes_cant * item.precio_unitario || 0);
      }, 0);
      const manoDeObra = parseFloat(this.name) || 0; // Incluye la mano de obra
      return itemsTotal + manoDeObra; // El subtotal sigue incluyendo mano de obra
    },
  },
  watch: {
  abono_requerido() {
    this.validateAbono();
  },
  showModal(newVal) {
    if (newVal && this.item !== undefined) {
      const { data_ov_rechazada } = this.item;
        if (!data_ov_rechazada) {
          return;
        }
       /*  if (data_ov_rechazada.mano_de_obra) {
          this.name = data_ov_rechazada.mano_de_obra;
        }

        if (data_ov_rechazada.requiere_abono !== undefined) {
          this.checked = data_ov_rechazada.requiere_abono;
        } */
/* 
        if (data_ov_rechazada.abono_requerido) {
          this.abono_requerido = data_ov_rechazada.abono_requerido;
        } */

        if (data_ov_rechazada.detalle_venta && Array.isArray(data_ov_rechazada.detalle_venta)) {
          this.items = data_ov_rechazada.detalle_venta.map(detalle => ({
            partes_name: detalle.parte.part_number,
            partes_cant: detalle.cantidad,
            precio_unitario: detalle.precio_unitario,
            id_parte: detalle.id
          }));
        }
    }
  },
},

};
</script>

<style lang="scss">
@import '@/style/colors.scss';

.btn-add-item {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  cursor: pointer;

}

.icon-add {
  color: #FECE2F;
}

.trash-icon {
  color: #FECE2F;
}

.form-subtitle {
  color: $samtek-black;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-left: 0.5em;

  >h3 {
    font-weight: bold;
    font-size: 18px;
    margin: 0;
  }

  +hr {
    margin: 0.5em 0 1.5em;
  }
}

.categoria,
.comentarios {
  display: block;
  font-size: 15px;
  font-weight: normal;
}

.comentario_text,
.categoria_text {
  color: var(--grey-textos-525252, #525252);
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitle_repuesto {
  color: var(--negro-textos-23201-c, #23201C);
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.title {
  color: var(--negro-textos-23201-c, #23201C);
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.textarea-height {
  height: 150px;
}
</style>
