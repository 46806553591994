<template lang="pug">
  div
    ListOV
</template>

<script>
import ListOV from '../components/OV/ListOV.vue';

export default {
  name: 'SalesOpportunityView',
  components: {
    ListOV
  },
  data() {
    return {
      isModalVisible: false
    };
  }
};
</script>

<style lang="scss" scoped>
.btn-new-ov {
  background-color: #FECE2F;
  border: none;
  color: black;
}
.back-arrow {
  margin: 2px 25px 7px 0px;
  cursor: pointer;
  color: #FECE2F;
}
</style>
