<template lang="pug">
#IngresarPago
  b-button(
    variant="action"
    @click="showModalPayment()"
  )
    | Ingresar Pago
  ModalPayment(:showModal="showModal" :item="this.dataOv"  @close="showModal = false" :reloadList="reloadList")
</template>
<script>
import ModalPayment from '../../ModalPayment.vue';

export default {
  name: 'IngresarPago',
  components: {
    ModalPayment
  },
  props: {
    dataOv: {
      type: Object,
      required: true
    },
    reloadList: {
      type: Function,
    },
  },
  data () {
    return {
      showModal: false

    }
  },
  methods: {
    showModalPayment() {
      console.log('showModalPayment', this.dataOv)
      this.showModal = true;
    },
  }

}
</script>
