<template lang="pug">

div
  div.container
    b-row
      b-col(cols='6').d-flex.justify-content-flex-start
        b-icon.back-arrow.h3.mb-4.cursor-pointer(
          icon="arrow-left-square-fill"
          variant=""
          @click="$router.go(-1)"
        )
        h4.pl-3.title Oportunidades de Venta
      b-col.tittle-head(cols='6')
        GenerarCotizacion(
          :reloadList="fetchData"
        )
        div.pl-2
          b-dropdown(variant="primary")
            template(#button-content)
              span.primary-btn Exportar
              b-icon(icon="box-arrow-in-right")
            b-dropdown-text.export-text Exportar:
            
            b-dropdown-item(@click="showExportRangeModal = true")
              b-icon(icon ="calendar3").mr-2
              | Rango de Fecha
              b-col(cols='3')
        div
          b-form-group().mt-3.ml-2
            b-form-input(
              v-model="paramsSearch"
              placeholder="Ingrese su búsqueda..."
              @input="buscar"
            ).search-input   
            b-input-group-append
              b-icon(icon="x"  variant="primary" font-scale="1.5" @click="paramsSearch = ''").search-icon

  b-container
    b-tabs(pills card lazy v-model= "tabSelected"
      @activate-tab="onTabChange"
    )
      b-tab(
        v-for="estado in estados"
        :key="estado.estado"
        :title="`${estado.estado} (${estado.cantidad})`"

      )
    b-row
      b-col
        b-table(
          :items="items"
          :fields="fields"
          hover
          resposive
          :busy="loadingTable"

        )
          template(#table-busy)
            div.text-center.p-3
              b-spinner(label="Cargando...").mr-2
              span Cargando datos...
          template(#cell(index)="data")
            span {{ data.index + 1 }}
          template(#cell(id)="data")
            span(
              v-if="!data.item.ov_rechazada"
            ) {{ data.item.id }}
            b-badge.badge-rechazada(
              v-if="data.item.ov_rechazada"
              variant="danger"
              v-b-tooltip.hover :title="`Oportunidad creada a partir de la OV rechazada: ${data.item.ov_rechazada}`"
            ) {{ data.item.id }}
          template(#cell(rma)="data")
            span {{ data.item.rma || '-'}}
          template(#cell(monto_total_neto)="data")
            span {{ (data.item.monto_total_neto * 1.19) | formatCurrency }}
          template(#cell(cotizacion_pdf)="data")
            a(
              :href="informeUrl(data.item.cotizacion_pdf)"
              target="_blank",
              v-if="data.item.cotizacion_pdf"
            ) Descargar Cot-{{data.item.id}}
            p(v-else) -
          template(#cell(acciones)="data")
            OVActionsIndex(:ovId="data.item.id", :dataOv="data.item" :reloadList="init")
        div.text-center.mt-3(v-if="items.length === 0 && !loadingTable")
            span No hay datos para mostrar    
        b-pagination(
          v-model="currentPage",
          :total-rows="totalRows",
          align="right"
          prev-text="Ant.",
          next-text="Sig.",
          @change="handlePageChange"
        )

    b-modal(v-model="showExportRangeModal" title="Exportar Oportunidades de Venta" hide-footer)
      b-form-group
        label.label-color Desde
        b-form-datepicker(v-model="start_date" placeholder="Por favor selecciona una fecha")
      b-form-group
        label.label-color Hasta
        b-form-datepicker(v-model="end_date" placeholder="Por favor selecciona una fecha")
      div.row
        div.col.p-2.d-flex.flex-row.justify-content-end
          b-button.secondary-btn(
            @click="closeModal()"
            variant="outlined"
          ) Cancelar
          b-button.ml-2.primary-btn(
            @click="exportExcel()"
            variant="samtek-yellow"
            :disabled="!start_date || !end_date || loading"
          )
            b-spinner.ml-4.mr-4(small v-if="loading")
            span(v-if="!loading") Exportar

</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from "lodash";
import OVActionsIndex from './OVActions/OVActionsIndex.vue'
import GenerarCotizacion from './OVActions/Actions/GenerarCotizacion.vue';
import { backUrl } from '../../../settings'

export default {
  name: 'ListOV',
  components: {
    OVActionsIndex,
    GenerarCotizacion
  },
  data() {
    return {
      item: {},
      showExportRangeModal: false,
      loading: false,
      loadingTable: false,
      start_date: null,
      end_date: null,
      paramsSearch: '',
      currentPage: 1,
      pageSize: 20,
      sortBy: null,
      sortDesc: false,
      items: [],
      estados: [],
      tabSelected: null,
      tabNameSelected: null,
      totalRows: 0,
      fields: [
        { key: 'id', label: 'ID', class: 'id-column', sortable: true },
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'apellido', label: 'Apellido', sortable: true },
        { key: 'correo_electronico', label: 'Correo Electronico', sortable: true },
        { key: 'telefono', label: 'Teléfono', sortable: true },
        { key: 'estado', label: 'Estado', sortable: true },
        { key: 'rma', label: 'RMA', sortable: true },
        { key: 'monto_total_neto', label: 'Monto Total', sortable: true },
        { key: 'cotizacion_pdf', label: 'Cotización', sortable: true },
        { key: 'acciones', label: 'Acciones', sortable: true, class: 'actions-column' },
      ]
    };
  },
  methods: {
    ...mapActions('OVStore', [
      'listOvAll',
      'getEstadosOV',
      'exportOvExcelRange'
    ]),
    informeUrl(pdf) {
      return `${backUrl}${pdf}`
    },
    buscar: debounce(async function () {
      if(this.paramsSearch && this.paramsSearch.length > 1) {
        this.loadingTable = true; 
        try {
          const res = await this.listOvAll({ page: 1, estado: null, params: this.paramsSearch });
          this.items = res.results;
          const indexTodas = this.estados.findIndex(e => e.estado === 'Todas');
          if (indexTodas !== -1) {
            this.tabSelected = indexTodas;
            this.tabNameSelected = "Todas";
          }
        } catch (error) {
        console.error(error);
      } finally {
        this.loadingTable = false;
      }
      } else {
        this.fetchData()
      }
    }, 900),

    async getEstados() {
      const res = await this.getEstadosOV()
      // Agregar un estado para mostrar todos los OV
      this.estados = []
      this.estados = this.estados.concat(res)
      this.tabNameSelected = this.estados[0].estado
      this.estados.push({ estado: 'Todas', cantidad: res.reduce((a, b) => a + b.cantidad, 0) })
    },

    async fetchData(page = 1) {
      this.items = []
      this.totalRows = 0
      try {
          const res = await this.listOvAll({ page, estado: this.tabNameSelected })
          this.items = res.results;
          this.totalRows = res.count;
        } catch (error) {
          console.log(error)
        }
    },
    onTabChange(tab) {
      this.currentPage = 1
      this.tabNameSelected = this.estados[tab].estado
      if(this.tabNameSelected === 'Todas' && this.paramsSearch) {
        return;
      }
      this.fetchData(this.currentPage)
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
    async init() {
      await this.getEstados()
      this.fetchData()
    },
    closeModal() {
      this.start_date = null
      this.end_date = null
      this.showExportRangeModal = false
      this.loading = false
    },
    async exportExcel() {
      const date = {
        fecha_inicio: this.start_date,
        fecha_final: this.end_date,
      }
      console.log(date)
      this.loading = true
      const res = await this.exportOvExcelRange(date)
      console.log(res, 'response')
      if (res.data === 'No hay Oportunidades de Venta disponibles en el rango seleccionado') {
        this.$bvToast.toast(res.data, {
          title: 'Sin datos',
          variant: 'danger',
        })
        this.loading = false
      } else if (res.status === 200) {
        this.$bvToast.toast(`Te hemos enviado un correo${this.user.email ? ` a  ${this.user.email}!` : '!'}`, {
          title: 'Exportado con éxito!',
          variant: 'success',
        })
        this.closeModal()
      } else {
        this.$bvToast.toast(res.data, {
          title: 'Error',
          variant: 'danger',
        })
        this.closeModal()
      }
        
      
    }
  },
  mounted() {
    this.init()
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
  },
  watch: {
    showExportRangeModal(val) {
      if (!val) {
        this.closeModal()
      }
    },
    paramsSearch: "buscar",
  }

}
</script>
<style lang="scss" scoped>
@import '@/style/colors.scss';

::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link  {
  background-color: #F4C235;
  color: black;
  font-weight: bold;
  border-radius: 0.50rem;

}

::v-deep .nav-item a {
  color: #525252;
  font-weight: bold;
}

::v-deep .card-header-pills {
  background-color: #F5F6FA;
  border-radius: 0.50rem;
}
::v-deep .nav-item {
  margin: 6px;
}
::v-deep .card-header {
  background-color: #FFF;
}

::v-deep .tab-content > .active  {
  display: none;
}
::v-deep .b-table thead th {
  background-color: #DDDDE1;
  color: #525252;
  border: none;
}

::v-deep .b-table tbody td {
  border: none;
  background-color: #FFF;
}
::v-deep .b-table tbody tr td:first-child {
  font-weight: bold;
  color: black;
}
::v-deep .id-column {
  width: 60px;
}
::v-deep .actions-column {
  width: 200px;
}
.btn-action {
  background-color: #F4C235;
  color: black;
  border-radius: 0.50rem;
  border: none;
  &:hover {
    background-color: #F4C235;
    color: black;
    box-shadow: none;
  }
}
::v-deep .page-item.active .page-link {
  background-color: #F4C235;
  color: black;
}
::v-deep .page-link {
  color: black;
  border-color: black;
  border: 1px solid black;
  &:hover {
    color: black;
    border: 1px solid black;
    box-shadow: none;
  }
}
::v-deep .form-group label {
  color: #6c757d !important;
  font-weight: bold;
}
::v-deep .dropdown-item {
  font-size: 0.875rem;
}
::v-deep .b-dropdown-text {
  font-size: 0.875rem;
}
::v-deep .dropdown-item:hover {
  background-color: $samtek-yellow;
  border-color: #5b73e8 !important;
}
::v-deep .secondary-btn {
  border-color: $samtek-yellow;
  color: $samtek-black;
  

  &:hover {
    color: black;
    background-color: rgba(254, 206, 47, 0.1);
    border-color: #E5B823;
    box-shadow: none;
  }
}
::v-deep .tittle-head {
  display: flex; 
  justify-content: end; 
  align-items: center;
  white-space: nowrap;
}
.search-input {
  width: 260px;
  float: right;
}
.badge-rechazada {
  font-size: 12px; 
  cursor : pointer;
}
.search-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 10;
  cursor: pointer;
}

</style>
