<template lang="pug">
#GenerarCotizacion
  b-button(:variant="dataOv ? 'action' : 'primary'" @click="showModal")
    | {{ dataOv ? 'Ver' : 'Generar' }} Oportunidad de Venta
    b-spinner.ml-2.mb-1(v-if="loading" small)
  //- Modal de registro de OV
  b-modal(v-model="modal" title="Oportunidad de Venta" id="GenerarCotizacion")
    p.parraf Una vez completo este formulario, envíalo al cliente para que corrobore los datos y continuar con la operación.
    b-form-group(label="Nombre" label-for="nombre")
      b-form-input(v-model="form.nombre" id="nombre" placeholder="Nombre" required, :disabled="auxOvId != null" :state="$v.form.nombre.$dirty ? !$v.form.nombre.$invalid : null")
      b-form-invalid-feedback( v-if="!$v.form.nombre.$dirty || !$v.form.nombre.required")
        | Campo requerido
    b-form-group(label="Apellido" label-for="apellido")
      b-form-input(v-model="form.apellido" id="apellido" placeholder="Apellido" :disabled="auxOvId != null" )
    b-form-group(label="Correo" label-for="correo")
      b-form-input(v-model="form.correo" id="correo" placeholder="Correo" :disabled="auxOvId != null" 
        type="email" :state="$v.form.correo.$dirty ? !$v.form.correo.$error : null")
      b-form-invalid-feedback( v-if="!$v.form.correo.$dirty || !$v.form.correo.email")
        | Correo inválido
      b-form-invalid-feedback( v-if="!$v.form.correo.$dirty || !$v.form.correo.maxLength")
        | Correo demasiado largo
    b-form-group(label="Teléfono" label-for="telefono")
      b-form-input(v-model="form.telefono" id="telefono" placeholder="Teléfono" :disabled="auxOvId != null" 
        type="number" :state="$v.form.telefono.$dirty ? !$v.form.telefono.$error : null")
      b-form-invalid-feedback( v-if="!$v.form.telefono.$dirty || !$v.form.telefono.numeric")
        | Debe ser numérico
      b-form-invalid-feedback( v-if="!$v.form.telefono.$dirty || !$v.form.telefono.minLength")
        | Debe ser de 11 caracteres máximo
    span(v-if="$v.general.$error" class="general-error") Debe completar al menos uno de los campos: Teléfono o Correo.
    b-form-group.mt-2(label="Comentario" label-for="comentario")
      b-form-textarea(v-model="form.comentario" id="comentario" placeholder="Comentario" rows="3", :disabled="auxOvId != null")
    b-form-group(v-if="auxOvId" label="URL Formulario" label-for="linkForm")
      div(class="d-flex align-items-center cursor-pointer" @click="copyLink")
        b-input-group
          b-form-input(:value="linkPrecompletado" readonly, id="linkForm", style="pointer-events: none; flex: 1;")
          template(#append)
            b-input-group-text
              img(src="@/assets/icons/content-copy.svg" alt="Acciones" style="width: 23px; height: 23px; margin-right: 5px")
    b-form-checkbox(v-model="form.send_email" id="send_email", v-else) Enviar link automáticamente
    template(#modal-footer='{ ok, cancel, hide }')
      b-button.spaced-btn(size='sm' variant='primary' @click='handleOk' :disabled="loading", v-if="auxOvId == null") Generar Oportunidad de Venta
        b-spinner.ml-2(v-if="loading" small)
      b-button.spaced-btn(size='sm' variant='primary' @click='hideModal' v-else) Cerrar
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { minLength, maxLength, required, email, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'GenerarCotizacion',
  props: {
    dataOv: {
      type: Object,
      required: false,
    },
    reloadList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      modal: false,
      auxOvId: null,
      form: {
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        comentario: '',
        send_email: false,
      },
      linkPrecompletado: '',
    }
  },
  validations: {
    form: {
      nombre: { required, minLength: minLength(3) },
      correo: { email, maxLength: maxLength(60) },
      telefono: { numeric, maxLength: maxLength(11) },
    },
    general: {
      requiredAtLeastOne(value, vm) {
        return vm.form.telefono.trim() !== "" || vm.form.correo.trim() !== "";
      },
    },
  },
  methods: {
    ...mapActions('OVStore', ['getOvDetail', 'createOv']),
    copyLink() {
      console.log('Copiando link')
      navigator.clipboard.writeText(this.linkPrecompletado)
      // Pequeño feedback para el usuario
      this.$bvToast.toast('Link copiado al portapapeles', {
        title: 'Copiado',
        variant: 'success',
        solid: true,
      })
    },
    async showModal() {
      this.loading = true
      if (this.dataOv) {
        await this.getOvDetail(this.dataOv.id)
        this.form = {
          nombre: this.ovDetail.nombre,
          apellido: this.ovDetail.apellido,
          correo: this.ovDetail.correo_electronico,
          telefono: this.ovDetail.telefono,
          comentario: this.ovDetail.comentario,
          send_email: false,
        }
        this.linkPrecompletado = this.ovDetail.link_precompletado
      } else {
        this.cleanForm()
        this.auxOvId = null
      }
      this.loading = false
      this.modal = true
    },
    hideModal() {
      this.modal = false
    },
    cleanForm() {
      this.form = {
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        comentario: '',
        send_email: false,
      }
    },
    async handleOk() {
      this.$v.$touch()
      console.log(this.$v)
      if (this.$v.$error) {
        return
      }
      this.loading = true
      const resp = await this.createOv(this.form)
      this.auxOvId = resp.data.id
      this.linkPrecompletado = resp.data.link
      this.loading = false
      this.$bvToast.toast('Oportunidad de Venta generada', {
        title: 'Generada',
        variant: 'success',
        solid: true,
      })
      this.reloadList()
    },
  },
  computed: {
    ...mapGetters('OVStore', ['ovDetail']),
  },
  created() {
    this.auxOvId = this.dataOv?.id
  },
}
</script>
<style lang="scss" scoped>
@import url('../Styles/Index.scss');
#GenerarCotizacion {
  .input-group-text {
    border-color: #525252 !important;
    border-left-color: transparent !important;
    padding: 0;
  }
  #linkForm {
      border-right-color: transparent !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #23201C !important;
    border-color: #23201C !important;
  }
  .general-error {
    color: red;
    font-size: 0.8em;
  }
}
</style>
