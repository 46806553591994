<template lang="pug">
    b-modal(:id="'modal-cotization'" :visible="showModal" size="md" hide-footer @hide="handleModalClose" :no-close-on-backdrop="true")
      template(#modal-title)
        h5.modal-title Información de Pago ID {{ item.id }}
      div
        b-row
          b-col(cols="12").mb-3
            img(src="@/assets/icons/money.svg" alt="Acciones" style="width: 23px; height: 23px; margin-right: 8px")
            span.subtitle_montos Valores
          b-col(cols="12").d-flex.align-items-center
            b-form-checkbox( name="check-button" :checked="item.requiere_abono" disabled class="me-2")
            span Requiere Abono

          b-col(cols="12")
            b-row
              b-col(cols="12").mt-2
                span.subtitle_montos Monto a Pagar
                span.float-right {{ formatearPeso((item.monto_total_neto * 1.19 )|| 0) }}
              b-col(cols="12").mt-2
                span.subtitle_montos Monto Abonado
                span.float-right {{ formatearPeso(item.monto_abonado|| 0) }}
              b-col(cols="12").mt-2
                span.subtitle_montos Total a pagar
                span.float-right {{ formatearPeso((item.monto_total_neto * 1.19 - item.monto_abonado )|| 0) }}
        b-row.mt-4
          b-col(cols="12")
            b-form-group
              label Comentario
              b-form-textarea(placeholder="Agrega algún dato de ser necesario" v-model="comentario"  class="textarea-height")

        b-button.float-left(variant="info" @click="closeModal") Cancelar
        b-button.float-right(variant="primary" @click="handlePayment") Marcar como Pagado
    </template>

  <script>
  import { mapActions } from 'vuex';

  export default {
    name: 'ModalPayment',
    props: {
      showModal: {
        type: Boolean,
        required: true
      },
      item: {
        type: Object,
        required: false
      },
      reloadList: {
        type: Function,
      },
    },
    data() {
      return {
        comentario: '',
      };
    },
    methods: {
      ...mapActions('OVStore', ['sendPayment']),
      formatearPeso(valor) {
        return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(valor);
      },
      closeModal() {
        this.$emit('close');
      },
      handleModalClose() {
        this.closeModal();
      },
      async handlePayment() {
        try {
          const response = await this.sendPayment({
            comentario_cierre: this.comentario,
            id_oportunidad_venta: this.item.id
          });
          if (response.status === 200) {
            this.$bvToast.toast('Pago registrado.', {
              variant: 'success',
              solid: true
          })
            this.closeModal();
            this.reloadList();
          }
        } catch (error) {
          console.log(error)
          this.$bvToast.toast('Error al registrar pago.', {
            variant: 'danger',
            solid: true
          })

        }

      }
    }
  };
  </script>

  <style lang="scss">
  @import '@/style/colors.scss';

  .btn-add-item {
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    cursor: pointer;

  }

  .icon-add {
    color: #FECE2F;
  }

  .trash-icon {
    color: #FECE2F;
  }

  .form-subtitle {
    color: $samtek-black;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding-left: 0.5em;

    >h3 {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }

    +hr {
      margin: 0.5em 0 1.5em;
    }
  }

  .categoria,
  .comentarios {
    display: block;
    font-size: 15px;
    font-weight: normal;
  }

  .comentario_text,
  .categoria_text {
    color: var(--grey-textos-525252, #525252);
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .subtitle_montos {
    color: var(--negro-textos-23201-c, #23201C);
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .title {
    color: var(--negro-textos-23201-c, #23201C);
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .textarea-height {
    height: 150px;
  }
  </style>
