<template lang="pug">
#PedirRepuestosBodega
  b-button(
    variant="action"
    @click="showModalRepuestos()"
  )
    | Pedir Repuestos Bodega
  b-modal(
    v-model="showModal"
    :title="'Pedir Repuestos Bodega ID ' + dataOv.id"
  )
    b-form-group.mb-4(
      label="RMA"
      label-for="rma"
    )
      b-form-input(
        id="rma"
        :state="$v.rma.$dirty ? !$v.rma.$error : null"
        v-model="rma"
        placeholder="Ingrese RMA (10 caracteres)"
      )
      b-form-invalid-feedback(
        v-if="$v.rma.$dirty && !$v.rma.minLength"
      ) Debe ser de 15 caracteres
      b-form-invalid-feedback(
        v-if="$v.rma.$dirty && !$v.rma.required"
      ) Campo requerido
    b-row
      b-col(cols="12")
        img(src="@/assets/icons/replacement.svg" alt="Repuestos" style="width: 23px; height: 23px; margin-right: 5px")
        span.title.ml-2 Repuestos
      //- tabla sin bordes
      table.table.table-borderless.table-hover
        thead
          tr
            th N° De serie
            th Descripción
            th Disponible
        tbody
          tr(v-for="(repuesto, index) in repuestosForm" :key="index")
            td {{ repuesto.nSerie }}
            td {{ repuesto.descripcion }}
            td
              b-form-checkbox(
                v-model="repuesto.disponible"
              ) Sí
    template(#modal-footer='{ ok, cancel, hide }')
      .d-flex.justify-content-between(style='width: 100%')
        b-button(size='sm' variant='secondary' @click='hideModal' :disabled="loading") Cancelar
          b-spinner(v-if="loading" small)
        b-button(size='sm' variant='primary' @click='saveParts' :disabled="loading") Aceptar
          b-spinner(v-if="loading" small)
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import print from '@/utils/print'
import moment from 'moment'

export default {
  name: 'PedirRepuestosBodega',
  props: {
    dataOv: {
      type: Object,
      required: true
    },
    reloadList: {
      type: Function,
    },
  },
  data () {
    return {
      showModal: false,
      loading: false,
      rma: '',
      repuestosForm: [
        {
          id: '',
          nSerie: '',
          disponible: false
        }
      ],
    }
  },
  validations: {
    rma: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('OVStore', ['requestParts']),
    showModalRepuestos() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    printOVParts() {
      const oportunidadVenta = this.dataOv
      oportunidadVenta.rma = this.rma
      oportunidadVenta.vendedor = `${this.user.first_name} ${this.user.last_name}`
      print(
        this.dataOv.id, 
        this.repuestosForm, 
        oportunidadVenta, 
        moment().format('DD/MM/YYYY H:mm:ss'),
        'OV'
      )
    },
    saveParts() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.loading = true;
      const repuestos = this.repuestosForm.map((item) => {
        return {
          id_detalle_venta: item.id,
          repuesto_disponiblidad: item.disponible
        }
      });
      this.requestParts({
        id_oportunidad_venta: this.dataOv.id,
        rma: this.rma,
        lista_detalle: repuestos
      }).then(() => {
        this.printOVParts()
        this.loading = false;
        this.reloadList();
        this.hideModal();
      });
    }
  },
  created() {
    this.repuestosForm = this.dataOv.detalle_venta.map((item) => {
      return {
        id: item.id,
        nSerie: item.parte.part_number,
        disponible: true,
        descripcion: item.parte.description,
        real_location: item.parte.real_location
      }
    });
  }
}
</script>
<style lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #23201C !important;
  border-color: #23201C !important;
}
</style>
