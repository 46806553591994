<template lang="pug">
#OVActions
  .actions-buttons
    b-dropdown(
      variant="primary"
      style="width: 130px"
      no-caret
      right
      v-if="auxOvActions.length > 0"
    )
      template(v-slot:button-content)
        span
          img(src="@/assets/icons/actions.svg" alt="Acciones" style="width: 23px; height: 23px; margin-right: 5px")
          b-spinner(v-if="loading" small)
          span(v-else) Acciones
      b-dropdown-item(
        v-for="action in actions"
        v-if="auxOvActions.includes(action.actionCondition) && loading === false"
        :key="action.actionCondition"
      )
        component(:is="action.component" :reloadList="reloadAll" :dataOv="dataOv")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import GenerarCotizacion from './Actions/GenerarCotizacion.vue'
import CompletarCotizacion from './Actions/CompletarCotizacion.vue';
import AceptarCotizacion from './Actions/AceptarCotizacion.vue'
import RechazarCotizacion from './Actions/RechazarCotizacion.vue';
import PedirRepuestosBodega from './Actions/PedirRepuestosBodega.vue';
import PrepararNuevaCotizacion from './Actions/PrepararNuevaCotizacion.vue';
import CerrarOVRechazado from './Actions/CerrarOVRechazado.vue';
import RepuestosRecibidosBodega from './Actions/RepuestosRecibidosBodega.vue';
import RepuestosRecibidosEjecutivo from './Actions/RepuestosRecibidosEjecutivo.vue';
import IngresarPago from './Actions/IngresarPago.vue';

export default {
  data() {
    return {
      loading: false,
      auxOvActions: [],
      actions: [
        {
          component: GenerarCotizacion,
          actionCondition: 'VerOvPrecompletada',
        },
        {
          component: CompletarCotizacion,
          actionCondition: 'CompletarCotizacion',
        },
        {
          component: AceptarCotizacion,
          actionCondition: 'AceptarCotizacion',
        },
        {
          component: RechazarCotizacion,
          actionCondition: 'RechazarCotizacion',
        },
        {
          component: PedirRepuestosBodega,
          actionCondition: 'PedirRepuestosBodega',
        },
        {
          component: PrepararNuevaCotizacion,
          actionCondition: 'PrepararNuevaCotizacion',
        },
        {
          component: CerrarOVRechazado,
          actionCondition: 'CerrarOVRechazado',
        },
        {
          component: RepuestosRecibidosBodega,
          actionCondition: 'RepuestosRecibidosBodega',
        },
        {
          component: RepuestosRecibidosEjecutivo,
          actionCondition: 'RepuestosRecibidosEjecutivo',
        },
        {
          component: IngresarPago,
          actionCondition: 'IngresarPago',
        },
      ],
    }
  },
  props: {
    ovId: {
      type: Number,
      required: true
    },
    dataOv:{
      type: Object,
      required: true,
    },
    reloadList: {
      type: Function,
    }
  },
  methods: {
    ...mapActions('OVStore', ['listOvActions']),
    async reloadActions(hasResponse=false, messageTitle=null, message=null, messageVariant=null) {
      console.log('reloadActions')
      this.loading = true
      await this.listOvActions(this.ovId)
      this.auxOvActions = [...this.ovActions]
      this.loading = false
      // Funcionalidad para la respuesta de las acciones
      if (hasResponse) {
        this.$bvToast.toast(message, {
          title: messageTitle,
          variant: messageVariant,
          solid: true,
        })
      }
    },
    reloadAll() {
      this.reloadActions()
      this.reloadList()
    }
  },
  computed: {
    ...mapGetters('OVStore', ['ovActions']),
  },
  created() {
    this.reloadActions();
  }
}
</script>
<style lang="scss" scoped>
@import url('./Styles/Index.scss');
</style>
<style lang="scss">
#OVActions {
  .actions-buttons {
    .dropdown-item, .dropdown-menu.show {
        padding: 0px !important;
    }
    .dropdown-item:active, .dropdown-item:focus, .dropdown-item.focus {
        background-color: #cdcdcd !important;
    }
  }
}
</style>
