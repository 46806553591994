<template lang="pug">
#AceptarCotizacion
  b-button(
    variant="action"
    @click="openModal"
  )
    | Aceptar Cotización
    b-spinner(v-if="loading" small)
  //- Modal para aceptar la cotización, marcar abono del cliente
  b-modal.hide-footer(v-model="showModal" no-close-on-backdrop, title="Aceptar cotización")
    b-form-group(:label="'Abono del cliente ($' + ovDetail.abono_requerido + ' CLP Mínimo)'" v-if="ovDetail.requiere_abono")
      b-form-input(type="number" v-model="abono" placeholder="Abono del cliente en pesos ($)" :state="$v.abono.$dirty ? !$v.abono.$error : null")
      b-form-invalid-feedback( v-if="!$v.abono.$dirty || !$v.abono.required")
        | Campo requerido
      b-form-invalid-feedback( v-if="!$v.abono.$dirty || !$v.abono.minValue")
        | No puede ser menor al abono mínimo de la cotización
    div(v-else)
      | Esta cotización no requiere abono, ¿desea continuar?
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(size='sm' variant='secondary' @click='hideModal' :disabled="loading") Cancelar
        b-spinner(v-if="loading" small)
      b-button(size='sm' variant='primary' @click='acceptQuotation' :disabled="loading") Aceptar
        b-spinner(v-if="loading" small)
</template>
<script>
import { required, minValue } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AceptarCotizacion',
  data() {
    return {
      showModal: false,
      loading: false,
      abono: null,
      abonoMinimo: 1,
    }
  },
  validations() {
    return {
      abono: {
        required,
        minValue: minValue(this.abonoMinimo),
      },
    };
  },
  props: {
    dataOv: {
      type: Object,
      required: true,
    },
    reloadList: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters('OVStore', ['ovDetail']),
  },
  methods: {
    ...mapActions('OVStore', ['getOvDetail', 'acceptOv']),
    async openModal() {
      this.loading = true
      await this.getOvDetail(this.dataOv?.id)
      this.abonoMinimo = this.ovDetail?.abono_requerido?.replace(/,/g, "") || 0;
      this.abonoMinimo = parseInt(this.abonoMinimo, 10);
      this.showModal = true
      this.loading = false
    },
    hideModal() {
      this.showModal = false
    },
    acceptQuotation() {
      // Validar primero el formulario
      this.$v.$touch()
      if (!this.$v.$error || !this.ovDetail.requiere_abono) {
        this.loading = true
        this.acceptOv({
          id_oportunidad_venta: this.dataOv?.id,
          monto_abonado: this.ovDetail.requiere_abono ? this.abono : 0,
        }).then((resp) => {
          console.log(resp)
          this.reloadList()
          this.loading = false
          this.hideModal()
        })
      }
    }
  }
}
</script>
