<template lang="pug">
#RechazarCotizacion
  b-button(
    variant="action"
    @click="openModal"
  )
    | Rechazar Cotización
  //- Modal para confirmar rechazo
  b-modal.hide-footer(v-model="showModal" no-close-on-backdrop, title="Rechazar cotización")
    h5.center ¿Estás seguro de rechazar la cotización?
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(size='sm' variant='secondary' @click='hideModal' :disabled="loading") No
        b-spinner(v-if="loading" small)
      b-button(size='sm' variant='primary' @click='rechazarCotizacion' :disabled="loading") Si
        b-spinner(v-if="loading" small)
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'RechazarCotizacion',
  data() {
    return {
      showModal: false,
      loading: false,
    }
  },
  props: {
    dataOv: {
      type: Object,
      required: true,
    },
    reloadList: {
      type: Function,
    },
  },
  methods: {
    ...mapActions('OVStore', ['declineOv']),
    async rechazarCotizacion() {
      this.loading = true
      try {
        await this.declineOv({ id_oportunidad_venta: this.dataOv?.id })
         this.$bvToast.toast('Se ha creado la OV a partir de la Oportunidad rechazada ', {
          variant: 'warning',
          title: 'OV Rechazada',
          autoHideDelay: 5000,
        })
        this.hideModal() 
        setTimeout(() => this.reloadList(), 1000)
      } catch (error) {
        this.$bvToast.toast('Error al rechazar la OV', {
          variant: 'danger',
          title: 'OV Rechazada',
          autoHideDelay: 5000,
        })
        
      }
      this.hideModal()
    },
    openModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.center {
  text-align: center;
  width: 100%;
}
</style>
