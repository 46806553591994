/* eslint-disable */
import settings from '../../settings'

const print = (ot, parts, data, now, type) => {
  const { rma, tecnico_asignado } = data
  console.log('print', { rma, tecnico_asignado, ot, parts, data, now })
  if (parts.length > 0) {
    try {
      const builder = new window.epson.ePOSBuilder() //eslint-disable-line
      builder.addTextAlign(builder.ALIGN_CENTER)
      builder.addTextStyle(false, false, true, builder.COLOR_1)
      builder.addText('Solicitud de partes\n')
      builder.addBarcode(
        rma,
        builder.BARCODE_CODE39,
        builder.HRI_NONE,
        builder.FONT_A,
        2,
        32
      )
      builder.addFeedLine(1)
      builder.addText(`RMA: ${rma}\n`)
      builder.addBarcode(
        rma,
        builder.BARCODE_CODE39,
        builder.HRI_NONE,
        builder.FONT_A,
        2,
        32
      )
      builder.addText(type === 'OV' ? `OV: ${ot}\n` : `OT: ${ot}\n`)

      if(type === 'OV') {
        builder.addText(
          `Vendedor: ${data.vendedor}\n`
        )
      } else {
        builder.addText(
          `Técnico : ${
            tecnico_asignado
              ? tecnico_asignado.first_name + tecnico_asignado.last_name
              : '-'
          }\n`
        )
      }
      builder.addText(`${now}\n`)
      builder.addFeedLine(1)
      builder.addTextLineSpace(20)
      builder.addTextStyle(false, false, false, builder.COLOR_1)
      parts.forEach((part) => {
        builder.addTextAlign(builder.ALIGN_CENTER)
        builder.addBarcode(
          part.parte.partNumber,
          builder.BARCODE_CODE39,
          builder.HRI_NONE,
          builder.FONT_A,
          2,
          32
        )
        builder.addTextAlign(builder.ALIGN_LEFT)
        builder.addFeedLine(1)
        if(type === 'OV') {
          builder.addText(`PartNumber: ${ part.nSerie }\n`)
          builder.addText(`Disponible: ${ part.disponible ? 'Si' : 'No' }\n`)
          builder.addText(`RealLocation: ${ part.real_location }\n`)
          builder.addText(`Descripción: ${ part.descripcion }\n`)
        } else {
          builder.addText(`PartNumber: ${part.parte.part_number ? part.parte.part_number : part.parte.partNumber }\n`)
          builder.addText(`Allocated ${part.allocated ? 'Si' : 'No'}\n`)
          builder.addText(`RealLocation: ${part.parte.real_location ? part.parte.real_location : part.parte.realLocation}\n`)
          builder.addText(`Descripción: ${part.parte.descripcion ? part.parte.descripcion : part.parte.description}\n`)
        }
        builder.addFeedLine(2)
      })
      builder.addCut(builder.CUT_FEED)
      const request = builder.toString()
      console.log('Builder OK')
      console.log('Conectando...')
      const printerIp = settings.printerIp || '10.0.1.200'
      const address =
        `https://${printerIp}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`
      const epos = new epson.ePOSPrint(address) //eslint-disable-line
      console.log('Imprimiendo...')
      epos.send(request)
      console.log('Impresión lista!')
    } catch (error) {
      console.log('Error de conexion')
      console.error(error)
    }
  } else {
    console.log('sin partes para imprimir')
  }
}
export default print
