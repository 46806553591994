<template lang="pug">
  #RepuestosRecibidosEjecutivo
    b-button(
      variant="action"
      @click="openModal"
    )
      | Repuestos Recibidos Ejecutivo
    //- Modal para confirmar rechazo
    b-modal.hide-footer(v-model="showModal" no-close-on-backdrop, title="Recibir repuestos Ejecutivo")
      h5.center ¿Estás seguro de recibir los repuestos del ejecutivo?
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='secondary' @click='hideModal' :disabled="loading") No
          b-spinner(v-if="loading" small)
        b-button(size='sm' variant='primary' @click='recibirEjecutivo' :disabled="loading") Si
          b-spinner(v-if="loading" small)
  </template>
  <script>
  import { mapActions } from 'vuex'

  export default {
    name: 'RechazarCotizacion',
    data() {
      return {
        showModal: false,
        loading: false,
      }
    },
    props: {
      dataOv: {
        type: Object,
        required: true,
      },
      reloadList: {
        type: Function,
      },
    },
    methods: {
      ...mapActions('OVStore', ['receivePartsExecutive']),
      async recibirEjecutivo() {
        this.loading = true
        await this.receivePartsExecutive({ id_oportunidad_venta: this.dataOv?.id })
        this.reloadList()
        this.loading = false
        this.hideModal()
      },
      openModal() {
        this.showModal = true
      },
      hideModal() {
        this.showModal = false
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  .center {
    text-align: center;
    width: 100%;
  }
  </style>
