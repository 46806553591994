<template lang="pug">
  #CompletarCotizacion
    b-button(
      variant="action"
      @click="showModalCotizacion()"
    )
      | Completar Cotización
    ModalCotization(:showModal="showModal" :item="this.dataOv"  @close="showModal = false", :reloadList="reloadList")
  </template>
<script>
import ModalCotization from '../../ModalCotization.vue';

export default {
  name: 'CompletarCotizacion',
  components: {
    ModalCotization
  },
  props: {
    dataOv: {
      type: Object,
      required: true
    },
    reloadList: {
      type: Function,
    },
  },
  data () {
    return {
      showModal: false

    }
  },
  methods: {
    showModalCotizacion() {
      this.showModal = true;
    },
  }
}
</script>
