<template lang="pug">
#PrepararNuevaCotizacion
  b-button(
    variant="action"
    @click="openModal"
  )
    | Preparar Nueva Cotización
  //- Modal para confirmar nueva cotización
  b-modal.hide-footer(v-model="showModal" no-close-on-backdrop, title="Preparar Nueva Cotización")
    h5.center ¿Estás seguro de preparar una nueva cotización?
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(size='sm' variant='primary' @click='prepararCotizacion' :disabled="loading") Si
        b-spinner(v-if="loading" small)
      b-button(size='sm' variant='primary' @click='hideModal' :disabled="loading") No
        b-spinner(v-if="loading" small)
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'PrepararNuevaCotizacion',
  data() {
    return {
      showModal: false,
      loading: false,
    }
  },
  props: {
    dataOv: {
      type: Object,
      required: true,
    },
    reloadList: {
      type: Function,
    },
  },
  methods: {
    ...mapActions('OVStore', ['prepareNewQuotation']),
    async prepararCotizacion() {
      this.loading = true
      await this.prepareNewQuotation({ id_oportunidad_venta: this.dataOv?.id })
      this.reloadList()
      this.loading = false
      this.hideModal()
    },
    openModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.center {
  text-align: center;
  width: 100%;
}
</style>
